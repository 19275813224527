<template>
  <div class="w-full h-full overflow-x-hidden flex flex-col" :class="isOpen ? width : widthClosed">
    <template v-if="!collapsable || isOpen">
      <slot name="header"></slot>

      <div class="flex gap-2 items-center px-4 pt-3">
        <div class="flex-1">
          <fw-heading muted :counter="data?.length" :counter-total="pagination.total_items">
            {{ dataTitle ?? $t('records') }}
          </fw-heading>
        </div>

        <Filters
          v-if="showFilters"
          :filters="filters"
          :selected-filters.sync="appliedFilters"
          :show-filter-label="false"
          @filter-changed="filterChanged"
        />

        <slot name="toolbar">
          <fw-button
            v-if="collapsable"
            :icon="isOpen ? 'sidebar-fold' : 'sidebar-unfold'"
            type="transparent"
            class="opacity-10 hover:opacity-100"
            @click.native="isOpen = !isOpen"
          />
        </slot>
      </div>

      <ContextualSearch
        v-if="showSearchbar"
        type="minimal"
        :loading="loading"
        :show-filters="false"
        :restore-filters="false"
        class="px-4 pt-4"
        @search="getData"
      />

      <div ref="sidebarList" class="my-4 px-4 flex-1 overflow-y-auto">
        <LoadingPlaceholder v-if="loading" list />

        <template v-else>
          <div v-if="data?.length > 0" class="pb-2 gap-2 flex flex-col">
            <slot name="records" :items="data" v-bind="{ ...extraData }"></slot>
          </div>

          <fw-panel-info v-else type="basic" class="text-center my-5 text-gray-500">
            {{ noDataLabel ?? $t('noRecordsFound') }}
          </fw-panel-info>
        </template>
      </div>

      <div v-if="pagination.total_pages > 1 && !loading">
        <BlockPagination
          :per-page="pagination.active_limit"
          :total="pagination.total_items"
          :total-pages="pagination.total_pages"
          :current.sync="pagination.current_page"
          :range-before="1"
          :range-after="1"
          @page-changed="pageChanged"
        />
      </div>
    </template>

    <div v-if="collapsable && !isOpen" class="p-3">
      <fw-button
        :icon="isOpen ? 'sidebar-fold' : 'sidebar-unfold'"
        type="transparent"
        @click.native="isOpen = !isOpen"
      />
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import Filters from '@/fw-modules/fw-core-vue/ui/components/search/Filters'

export default {
  components: {
    ContextualSearch,
    BlockPagination,
    LoadingPlaceholder,
    Filters
  },

  props: {
    apiCallFunction: {
      type: Function
    },

    scrollToElId: {
      type: String
    },

    injectPayload: {
      type: Object,
      default: () => {}
    },

    noDataLabel: {
      type: String
    },

    dataTitle: {
      type: String
    },

    dataKey: {
      type: String,
      default: 'data'
    },

    // Ex: [
    // 	{
    // 		key: 'status[]',
    // 		multifilter: true,
    // 		label: 'Status',
    // 		options: [
    // 			{
    // 				key: 'reset',
    // 				label: 'Tudo',
    // 			},
    // 			{
    // 				key: 'new',
    // 				label: 'Novo',
    // 			},
    // 			{
    // 				key: 'open',
    // 				label: 'Aberto',
    // 			},
    // 		],
    // 	},
    // ],
    filters: {
      type: Array,
      default: () => []
    },

    startingFilters: {
      type: Array,
      default: () => []
    },

    showSearchbar: {
      type: Boolean,
      default: true
    },

    showFilters: {
      type: Boolean,
      default: false
    },

    startsOpen: {
      type: Boolean,
      default: false
    },

    collapsable: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: 'w-[32rem]'
    }
  },

  data() {
    return {
      data: [],
      extraData: null,
      loading: false,
      searchTerm: '',
      appliedFilters: [],
      pagination: {
        current_page: 1,
        total_pages: 1,
        active_limit: 50,
        total_items: 0
      },
      isOpen: false,
      widthClosed: 'w-[4rem]'
    }
  },

  computed: {},

  mounted() {
    if (this.startingFilters?.length) {
      this.appliedFilters = this.startingFilters
    }
    this.isOpen = this.collapsable ? this.startsOpen : true
    this.getData({ term: this.searchTerm, filters: this.appliedFilters })
  },

  methods: {
    updateItemDetails(item) {
      console.log('updateitemDetails :>> ', item)
      let index = this.data?.findIndex(el => el.key == item.key)
      if (index > -1) {
        this.$set(this.data, index, item)
      }
    },

    async getData({ term, filters } = {}) {
      console.log('getData :>> ', { term, filters })
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.apiCallFunction({
          query: term,
          limit: this.pagination.active_limit,
          page: this.pagination.current_page,
          ...utils.setFiltersQuery(filters),
          ...this.injectPayload
        })
        console.log('sidebar response :>> ', response)

        this.data = response[this.dataKey]
        delete response[this.dataKey]
        const { pagination, ...extraData } = response
        this.pagination = pagination
        this.extraData = extraData
      })
      this.loading = false

      if (this.scrollToElId) {
        this.$nextTick(() => {
          const el = document.getElementById(this.scrollToElId)
          if (el) el.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },

    pageChanged(page) {
      console.log('pageChanged :>> ', page)
      this.pagination.current_page = page
      this.getData({ term: this.searchTerm, filters: this.appliedFilters })
    },

    filterChanged(newFilters) {
      console.log('filterChanged :>> ', newFilters)
      this.appliedFilters = newFilters
      this.getData({ term: this.searchTerm, filters: this.appliedFilters })
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "records": "Resultados",
    "noRecordsFound": "Sem registos a mostrar."
  },
  "en": {
    "records": "Resultados",
    "noRecordsFound": "No records to show."
  }
}
</i18n>
